/**
 * This file contains every vendor dependency styles which are related
 * to project.
 */
// Vendors

@import
// Bootstrap framework from `node_modules`
'~bootstrap/scss/bootstrap';
  
 // Font Awesome
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts'; 
@import
  '~@fortawesome/fontawesome-free/scss/fontawesome',
  '~@fortawesome/fontawesome-free/scss/regular',
  '~@fortawesome/fontawesome-free/scss/solid',
  '~@fortawesome/fontawesome-free/scss/brands';

@import
  // Ion Icons
'vendor/ion-iconsv4.2.0/scss/ionicons',
  // Animate.css from `node_modules`
'~animate.css/animate.css',
  // jquery ui range slider
'vendor/jquery-ui-range-slider/jquery-ui-range-slider';
// Owl-carousel from `node_modules`
$owl-image-path: '../images/vendor/owl-carousel/dist/';
@import '~owl.carousel/src/scss/owl.carousel';
